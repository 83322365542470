import React from 'react'
import { graphql } from 'gatsby'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import {
  Hero,
  Container,
  HeroContainer,
  LinkButton,
  TitleCaption,
  Section,
} from '../webhart-base'
import {
  colors,
  gradients,
  fonts,
  rhythm,
  transition,
} from '../webhart-base/utils/style'

import Layout from '../components/Layout'
import WorkList from '../components/WorkList'
import ScrollDown from '../components/ScrollDown'

const FeaturesGrid = styled('div')`
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
  max-width: ${215 * 5 - 1}px;
  padding-bottom: 20px;
`

const FeatureCard = styled('div')`
  padding: ${rhythm()};
  width: 100%;
  transition: ${transition};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  img {
    height: 60px;
    margin: ${rhythm(1 / 2)};
    filter: grayscale(1);
  }
  h4 {
    margin-bottom: ${rhythm(1 / 2)};
    width: 100%;
  }
  span {
    display: block;
    color: ${colors.lightBlue};
    font-size: ${rhythm(1 / 2)};
    max-width: 140px;
    margin: ${rhythm(1 / 2)} auto;
  }
  p {
    font-size: ${rhythm(1 / 2)};
    margin-bottom: ${rhythm(1 / 2)};
  }
  a {
    color: ${colors.orange};
    font-size: ${rhythm(1 / 2)};
    margin-top: auto;
  }
  a,
  p {
    filter: blur(3px);
  }
  &:hover {
    background: white;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
    * {
      filter: none;
    }
  }
`

import Logo from '../../static/images/Logo'

import Bike from '../../static/images/icons/fiets.svg'
import Code from '../../static/images/icons/code.svg'
import Tools from '../../static/images/icons/tools.svg'

import rocket from '../../static/images/icons/rocket.svg'
import mobile from '../../static/images/icons/mobile.svg'
import secure from '../../static/images/icons/secure.svg'
import SEO from '../../static/images/icons/SEO.svg'
import TypedText from '../components/TypedText'

export default ({ data }) => (
  <Layout>
    <Hero background={gradients.darkBlue} color={colors.orange} hideLogo>
      {/* <HeroContainer> */}
      <Logo
        css={css`
          width: auto;
          height: auto;
          max-width: 90%;
          max-height: 350px;
        `}
      />
      <div>
        <h2>coding artisan</h2>
        {/* <p
          css={css`
            color: white;
          `}
        >
          fancy catchphrase
        </p> */}
      </div>
      {/* </HeroContainer> */}
    </Hero>
    <Hero background="#eee" hideLogo>
      <HeroContainer>
        <div //intro component
          css={css`
            /* max-width: 450px; */
          `}
        >
          <h2>Hi, my name is Rien</h2>
          <TitleCaption
            css={css`
              && {
                max-width: 750px !important;
                span {
                  color: ${colors.lightBlue};
                  font-family: ${fonts.mono};
                }
              }
            `}
          >
            I’m a{' '}
            <TypedText>
              {['web developer', 'cyclist', 'bicycle mechanic']}
            </TypedText>{' '}
            from <span>Belgium</span> based in <span>Girona</span>, Spain.
            <br />I like{' '}
            {/* <TypedText>{['midnight working', 'long bike rides', 'cats (and Dora D.)']}</TypedText> and */}
            creating top-tier websites tailored to your needs.
          </TitleCaption>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              max-width: 300px;
              margin: ${rhythm()} auto;
              img {
                margin: ${rhythm(1 / 2)};
                height: 50px;
              }
            `}
          >
            <img src={Code} alt="code" />
            <img src={Bike} alt="bicycle" />
            <img src={Tools} alt="tools" />
          </div>
          <div>
            <LinkButton to="/about-me">more about me</LinkButton>
            <LinkButton background={colors.lightBlue} to="/work-together">
              work with me
            </LinkButton>
          </div>
        </div>
      </HeroContainer>
    </Hero>
    <Hero hideLogo>
      <HeroContainer>
        <h2>how to spot a killer website in the wild?</h2>
        <TitleCaption>
          All webhart websites roaming the endless planes of the world wide web
          were set free with following properties.
        </TitleCaption>
        <FeaturesGrid>
          <FeatureCard>
            <img src={rocket} alt="rocket" />
            <h4>rocket speed</h4>
            <span>two seconds to make people stay</span>
            <p>
              We live in a world of distraction. There’s nothing like the rush
              of a new notification. You want to grab your target’s attention
              before the next notification gets it.
            </p>
            {/* <Link to="/features">read more...</Link> */}
          </FeatureCard>
          <FeatureCard>
            <img src={mobile} alt="mobile" />
            <h4>responsive</h4>
            <span>the world lives in our pockets</span>
            <p>
              Responsive development goes beyond looks. With more than half of
              the online population using a mobile, websites need to adapt to
              all sorts of devices.
            </p>
            {/* <Link to="/features">read more...</Link> */}
          </FeatureCard>
          <FeatureCard>
            <img src={secure} alt="secure" />
            <h4>secure</h4>
            <span>safeguard your integrity</span>
            <p>
              Protect your audience, yourself, and your content. Webhart
              websites are created and deployed with security in mind. Reliable
              fallbacks are available in worst case scenario’s.
            </p>
            {/* <Link to="/features">read more...</Link> */}
          </FeatureCard>
          <FeatureCard>
            <img src={SEO} alt="loop" />
            <h4>seo</h4>
            <span>get to the front of the line</span>
            <p>
              Speed, responsiveness, and security are key in search engine
              optimisation. Getting your website to the top of the stack is step
              one in getting across that good impression.
            </p>
            {/* <Link to="/features">read more...</Link> */}
          </FeatureCard>
        </FeaturesGrid>
        <div>
          {/* <LinkButton background={colors.blue} color="white" to="/features">
            read more
          </LinkButton> */}
          <LinkButton
            background={gradients.orange}
            color="white"
            to="/work-together"
          >
            get started
          </LinkButton>
        </div>
      </HeroContainer>
    </Hero>
    <Section>
      <Container width="wide">
        <h2>my work</h2>
        <TitleCaption>Buckle in and see for yourself.</TitleCaption>
        <WorkList items={data.portfolio.edges} />

        <div>
          <LinkButton
            background={gradients.darkBlue}
            color="white"
            to="/portfolio"
          >
            all work
          </LinkButton>
          <LinkButton
            background={colors.lightBlue}
            color="white"
            to="/work-together"
          >
            build a website
          </LinkButton>
        </div>
      </Container>
      <ScrollDown />
    </Section>
  </Layout>
)

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    portfolio: allMarkdownRemark(
      filter: {
        frontmatter: {
          #draft: { ne: true }
          featured: { eq: true }
          templateKey: { eq: "site" }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          ...WorkListFragment
        }
      }
    }
  }
`
