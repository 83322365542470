import React from 'react'

import { graphql } from 'gatsby'

import Image from 'gatsby-image'
import Link from 'gatsby-link'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import {
  colors,
  rhythm,
  transition,
  packages,
} from '../webhart-base/utils/style'

const WorkGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(300px - ${rhythm(1 / 2)}), 1fr)
  );
  margin: 0 -${rhythm(1 / 2)};
  overflow: visible;
  padding: 0 ${rhythm(1 / 2)} ${rhythm(1)};
  grid-gap: ${rhythm(1 / 2)};
`
const PortfolioItem = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: ${rhythm(2 / 3)};
  transition: ${transition};
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  /* box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2); */

  & > * {
    margin: ${rhythm(1 / 3)} ${rhythm(1 / 2)};
  }
  &:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
    background: white;
  }
  a {
    font-size: 12px;
    color: ${colors.orange};
  }
  p {
    font-size: ${rhythm(1 / 2)};
  }
`

export const TagLabels = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    a,
    button {
      border: none;
      display: block;
      display: table-cell;
      font-size: 12px;
      background: ${colors.blue};
      color: white;
      align-items: flex-start;
      font-weight: 500;
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
      border-radius: 50px;
      padding: 0 ${rhythm(1 / 3)};
      margin: 3px;
      text-decoration: none;
    }
  }
`

const WorkList = ({ items, tag, tagClickHandler, hidePackage }) => {
  return (
    <WorkGrid>
      {items.map(
        ({ node }, i) =>
          (tag == undefined ||
            tag == node.frontmatter.package ||
            (node.frontmatter.tags &&
              node.frontmatter.tags.length > 0 &&
              node.frontmatter.tags.includes(tag))) && (
            <PortfolioItem key={i}>
              {node.frontmatter.image && (
                <Image fluid={node.frontmatter.image.childImageSharp.fluid} />
              )}
              {/* <Image
                fluid={
                  node.frontmatter.image
                    ? node.frontmatter.image.childImageSharp.fluid
                    : node.childScreenshot.screenshotFile.childImageSharp.fluid
                }
              /> */}
              <span
                css={css`
                  display: flex;
                  justify-content: space-between;
                  h4 {
                    margin: 0;
                  }
                  && a {
                    flex: 0 0 55px;
                    text-align: right;
                    color: ${colors.lightBlue};
                  }
                `}
              >
                <h4>{node.frontmatter.title}</h4>
                <OutboundLink
                  href={node.frontmatter.url}
                  target="_blank"
                  rel="noopener"
                >
                  visit site
                </OutboundLink>
              </span>
              <p>
                {node.frontmatter.description}{' '}
                {/* {node.html && (
                  <Link to={`/${node.fields.slug}`}>read more...</Link>
                )} */}
              </p>

              <TagLabels>
                {node.frontmatter.tags &&
                  node.frontmatter.tags.map((tag, i) => (
                    <React.Fragment key={i}>
                      {tagClickHandler ? (
                        <button onClick={() => tagClickHandler(tag)}>
                          {tag}
                        </button>
                      ) : (
                        <Link to={`/portfolio#${tag}`}>{tag}</Link>
                      )}{' '}
                    </React.Fragment>
                  ))}
                {!hidePackage && (
                  <Link
                    css={css`
                      &&& {
                        background: ${colors.lightBlue};
                      }
                    `}
                    to={`/work-together#${node.frontmatter.package}`}
                    key="package-type"
                  >
                    {packages[node.frontmatter.package]}
                  </Link>
                )}
              </TagLabels>
            </PortfolioItem>
          )
      )}
    </WorkGrid>
  )
}

export default WorkList

// Loads required data to use the `WorkList` component.
export const WorkListFragment = graphql`
  fragment WorkListFragment on MarkdownRemark {
    fields {
      slug
    }
    frontmatter {
      title
      package
      description
      url
      tags
      image {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    html
    # childScreenshot {
    #   screenshotFile {
    #     childImageSharp {
    #       fluid(maxWidth: 300, maxHeight: 300) {
    #         ...GatsbyImageSharpFluid
    #       }
    #     }
    #   }
    # }
  }
`
